import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { IconModule } from './../icon/icon.module';
import { ContentContainerComponent } from './containers/content-container/content-container.component';
import { MainContentComponent } from './containers/main-content/main-content.component';
import { MainLayoutComponent } from './containers/main-layout/main-layout.component';

@NgModule({
  declarations: [MainLayoutComponent, MainContentComponent, ContentContainerComponent],
  exports: [MainLayoutComponent, MainContentComponent, ContentContainerComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    IconModule,
    RouterModule,
  ]
})
export class MainLayoutModule { }
