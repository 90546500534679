import { Component, ElementRef, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs';

import { faStop, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ModalSize } from 'src/app/shared/models/enums/modal-size';

import { DemoSelectModalComponent } from '../../modals/demo-select-modal/demo-select-modal.component';
import { DemoService } from '../../services/demo.service';

@Component({
  selector: 'porcupine-demo-button',
  templateUrl: './demo-button.component.html',
  styleUrls: ['./demo-button.component.scss']
})
export class DemoButtonComponent {

  faStop = faStop;
  faVolumeMute = faVolumeMute;

  isPlaying: boolean = false;
  demo: Demo = {};
  demoSub: Subscription;
  @ViewChild('audioPlayer', { static: true }) player: ElementRef;

  constructor(
    private demoService: DemoService,
    private modal: NgbModal) { }

  stopDemo(): void {
    this.player.nativeElement.pause();
    this.player.nativeElement.currentTime = 0;
    this.isPlaying = false;
    if(this.demoSub) this.demoSub.unsubscribe();
  }

  demoClick(): void {
    const modal: NgbModalRef = this.modal.open(DemoSelectModalComponent, {
      size: ModalSize.LARGE,
      backdrop: 'static'
    });

    modal.result.then((demo: Demo) => {
      if(!demo) return;

      this.isPlaying = true;
      this.demo = demo;
      setTimeout(() => {
          if(this.isPlaying)
            this.player.nativeElement.play();
      }, 1000);

      this.demoSub = this.demoService.startDemo(demo.id).subscribe();
    });
  }

}
