import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EnvironmentService } from 'src/app/services/environment-service/environment.service';

@Injectable()
export class HttpBaseUrlInterceptor implements HttpInterceptor {
  private baseUrl: string;

  constructor(
    private environmentService: EnvironmentService) {
     }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let modified = request;
    if(request.url.startsWith('/')){
      if(!this.baseUrl) {
        this.baseUrl = this.environmentService.getEnvironment().baseUrl;
      }
      modified = request.clone({ url: `${this.baseUrl}${request.url}` });
    }

    return next.handle(modified);
  }
}
