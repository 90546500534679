export enum IconType {
  SPEEDOMETR ='SPEEDOMETR',
  UMBRELLA_WITH_DOLLAR ='UMBRELLA_WITH_DOLLAR',
  CIRCLE_CHEVRON_DOWN ='CIRCLE_CHEVRON_DOWN',
  DOLLAR_UNDER_HAND ='DOLLAR_UNDER_HAND',
  HUMAN_LOUPE ='HUMAN_LUPE',
  HUMAN ='HUMAN',
  SYNC_DOLLAR ='SYNC_DOLLAR',
  MENTAL_HEALTH ='MENTAL_HEALTH',
  LOCK ='LOCK',
  CHART_BAR ='CHART_BAR',
  LINE_DOT_CHART ='LINE_DOT_CHART',
  MICROPHONE ='MICROPHONE',
}
