import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { UploadsModule } from '@progress/kendo-angular-upload';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DropDownsModule,
    DropDownListModule,
    ProgressBarModule,
    GridModule,
    LayoutModule,
    UploadsModule,
    ButtonsModule,
    LabelModule,
    InputsModule,
    PDFExportModule,
    DateInputsModule
  ],
  exports: [
    DropDownsModule,
    DropDownListModule,
    ProgressBarModule,
    GridModule,
    LayoutModule,
    UploadsModule,
    ButtonsModule,
    LabelModule,
    InputsModule,
    PDFExportModule,
    DateInputsModule
  ]
})
export class KendoUiModule { }
