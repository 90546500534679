import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

const screenWithContextMenuConfiguration: string[] = [
  '/manage/admin/team/',
  '/manage/predictors/',
  '/wellness/predictors/',
  '/recorder/predictors/',
]

@Injectable({
  providedIn: 'root'
})
export class SideMenuService {
  private isContextMenu$: Subject<boolean> = new ReplaySubject(1);
  private isFolded$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private cacheState: { folded: boolean} | undefined = undefined;

  constructor(private router: Router) {
    this.setupSideMenuHadle();
  }

  isMenuFolded(): Observable<boolean> {
    return this.isFolded$.asObservable().pipe(shareReplay(1));
  }

  public collapseMenu(afterResize: boolean = false): void {
    if (!afterResize) this.cacheState = { folded: true };
    if (!this.isFolded$.getValue()) this.isFolded$.next(true);
  }

  public expandMenu(afterResize: boolean = false): void {
    if (!afterResize) this.cacheState = { folded: false };
    if (afterResize && this.cacheState) this.isFolded$.next(this.cacheState.folded);
    else if (this.isFolded$.getValue()) this.isFolded$.next(false);
  }

  public isContextMenuDisplayed(): Observable<boolean> {
    return this.isContextMenu$.asObservable();
  }

  private setupSideMenuHadle(): void {
    this.router.events
    .pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
          return screenWithContextMenuConfiguration.some((url) => event?.url?.includes(url))
    }))
    .subscribe((isContextMenu: boolean) => this.isContextMenu$.next(isContextMenu));
  }
}
