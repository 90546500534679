import { Component, HostListener, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { SideMenuService } from 'src/app/services/side-menu-service/side-menu.service';

@Component({
  selector: 'porcupine-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent {
  @Input() isUserLogged: any = false;
  public isFolded$: Observable<boolean> = this.sideMenuService.isMenuFolded();

  constructor(private sideMenuService: SideMenuService) {}

  @HostListener('click', ['$event.target'])
  public onClick(btn: { nodeName: string }): void {
    if (btn.nodeName === 'PORCUPINE-MANAGE-SIDE-NAVIGATION'
    || btn.nodeName === 'PORCUPINE-CONTEXT-SIDE-NAVIGATION') {
      this.sideMenuService.collapseMenu()
    }
  }
}
