import { Injectable } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { sideMenuButtonsConfiguration } from '../../context-side-navigation/configurations/side-menu-buttons.configuration';
import { scores, scoreTooltipDescription, scoreTooltipDescriptionWithTitle } from '../configuration/score-tooltip-description';

export interface ReportDescription {
  [key: string]: {
    title: string;
    desc: string
  };
}

@Injectable({
  providedIn: 'root'
})
export class ReportDescriptionService {
  constructor(private translate: TranslateService) { }

  public getConfTranslation(): Observable<{[key: string]: string}> {
    return combineLatest([
      this.translate.get('REPORT_TITLES_OPTIONS.DEFAULT') as Observable<string>,
      this.translate.get('REPORT_TITLES_OPTIONS.TEAM') as Observable<string>,
    ]).pipe(
      map(([reports, teamReport]) => {
      return { reports, teamReport }
    }))
  }

  public getCsvRaports(): Observable<{[key: string]: string}> {
    return combineLatest([
      this.translate.get('CSV_EXPORTS_RAPORTS.CANDIDATE_SUCCESS_PREDICTOR') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS.DEBT_COLLECTION_PREDICTOR') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS.DASHBOARD') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS.EMPLOYEE_CHURN') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS.LOAN_DEFAULT') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS.MENTAL_HEALTH') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS.MOBILE_TRADING') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS.PERSONALITY_ATTRIBUTE') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS.SALES_CONVERSION') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS.MENTAL_WELLNESS') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS_RECRUIT') as Observable<string>,
      this.translate.get('CSV_EXPORTS_RAPORTS_FRAUD_PREDICTOR') as Observable<string>,
      this.translate.get('CUSTOM_PRODUCTS') as Observable<string>,
    ]).pipe(
      map(([
        candidatesuccess,
        collections,
        dashboard,
        employeechurn,
        loandefault,
        mentalhealth,
        mobiletrading,
        personalityattribute,
        salesconversion,
        wellness,
        recruit,
        fraudpredictor,
        custom,
    ]) => {
      return {
        candidatesuccess,
        collections,
        dashboard,
        employeechurn,
        loandefault,
        mentalhealth,
        mobiletrading,
        personalityattribute,
        salesconversion,
        wellness,
        recruit,
        fraudpredictor,
        custom
       }
    }))
  }

  public getAggregation(): Observable<string[]> {
    return combineLatest([
      this.translate.get('AGGREGATION.DAILY'),
      this.translate.get('AGGREGATION.WEEKLY'),
      this.translate.get('AGGREGATION.MONTHLY'),
    ])
  }

  public getReportScale(): Observable<string[]> {
    return combineLatest([
      this.translate.get('SCORES.SCALE.HIGH'),
      this.translate.get('SCORES.SCALE.MODERATE'),
      this.translate.get('SCORES.SCALE.LOW'),
    ])
  }

  public getDays(): Observable<{[key: string]: string}> {
    return combineLatest([
      this.translate.get('DAYS.MONDAY') as Observable<string>,
      this.translate.get('DAYS.TUESDAY') as Observable<string>,
      this.translate.get('DAYS.WEDNESDAY') as Observable<string>,
      this.translate.get('DAYS.THURSDAY') as Observable<string>,
      this.translate.get('DAYS.FRIDAY') as Observable<string>,
      this.translate.get('DAYS.SATURDAY') as Observable<string>,
      this.translate.get('DAYS.SUNDAY') as Observable<string>,
    ])
    .pipe(
      map(([monday, tuesday, wednesday, thursday, friday, saturday, sunday]) =>
        ({ monday, tuesday, wednesday, thursday, friday, saturday, sunday })
      )
    )
  }

  public getProducts(): Observable<{[key: string]: string}> {
    return combineLatest([
      this.translate.get('PRODUCTS.RISK_MANAGEMENT') as Observable<string>,
      this.translate.get('PRODUCTS.COLLECTIONS') as Observable<string>,
      this.translate.get('PRODUCTS.TALENT') as Observable<string>,
      this.translate.get('PRODUCTS.RECRUTING') as Observable<string>,
      this.translate.get('PRODUCTS.SALES') as Observable<string>,
      this.translate.get('PRODUCTS.CONVERSION') as Observable<string>,
      this.translate.get('PRODUCTS.MENTAL_HEALTH') as Observable<string>,
      this.translate.get('PRODUCTS.WELLNESS') as Observable<string>,
      this.translate.get('PRODUCTS.DASHBOARD') as Observable<string>,
      this.translate.get('PRODUCTS.LOAN_DEFAULT') as Observable<string>,
      this.translate.get('PRODUCTS.DEBT_COLLECTION') as Observable<string>,
      this.translate.get('PRODUCTS.CANDIDATE_SUCCESS') as Observable<string>,
      this.translate.get('PRODUCTS.EMPLOYEE_CHURN') as Observable<string>,
      this.translate.get('PRODUCTS.SALES_CONVERSION') as Observable<string>,
      this.translate.get('PRODUCTS.PERSONALITY_ATTRIBUTE') as Observable<string>,
      this.translate.get('PRODUCTS.MENTAL_HEALTH_MONITORING') as Observable<string>,
      this.translate.get('PRODUCTS.MENTAL_HEALTH_SCREENING') as Observable<string>,
      this.translate.get('PRODUCTS.MOBILE_TRADING') as Observable<string>,
      this.translate.get('PRODUCTS.RECRUIT') as Observable<string>,
      this.translate.get('PRODUCTS.FRAUD') as Observable<string>,
      this.translate.get('PRODUCTS_MATCH_MAKING') as Observable<string>,
      this.translate.get('PRODUCTS_FROUD_PREDICTOR') as Observable<string>,
      this.translate.get('CUSTOM_PRODUCTS') as Observable<string>,
    ]).pipe(
      map(([riskManagment, collection, talent, recruting, sales, conversion, mentalHealth, wellness,
        dashboard, loanDefault, collections, candidateSuccess, employeeChurn, salesConversion,
        personalityAttribute, mentalHealthMonitoring, mentalHealthScreening, mobileTrading,
        recruit, fraud, matchMaking, fraudPredictor, customProducts]) => {
      return { riskManagment, collection, talent, recruting, sales, conversion, mentalHealth, wellness,
        dashboard, loanDefault, collections, deptCollection: collections, candidateSuccess, employeeChurn, salesConversion,
        personalityAttribute, mentalHealthMonitoring, mentalHealthScreening, mobileTrading,
        recruit, fraud, matchMaking, fraudPredictor, customProducts
      }
    }))
  }

  public getTranslatedConfig(): Observable<any> {
    return this.getProducts().pipe(map((translation) => {
      const translatedConfig = new Map();

      Array.from(sideMenuButtonsConfiguration).forEach(([key, value]) => {
        translatedConfig.set(key, {
          ...value,
          label: translation[value.label]
        })
        return translatedConfig;
      }, translatedConfig);

      return translatedConfig;
    }))
  }

  public translateDefinitions(): Observable<{[key: string]: string}> {
    const scoresArr = scores.map(
      s => this.translate.get(`SCORES.LABELS.${s}`).pipe(
        map((desc) => ({ [s]: desc }))
        )
      );

      return combineLatest([
        ...scoresArr,
        this.translate.get('SCORES.SCALE.LOW').pipe(map((desc) => ({ 'low': desc }))),
        this.translate.get('SCORES.SCALE.MODERATE').pipe(map((desc) => ({ 'moderate': desc }))),
        this.translate.get('SCORES.SCALE.HIGH').pipe(map((desc) => ({ 'high': desc }))),
      ]).pipe(map((response) => {
        return response.reduce((acc, value) => {
          return { ...acc, ...value }
        }, {})
      }));
  }

  public getReportDescriptions(): Observable<ReportDescription> {
    const scoresDesc = scoreTooltipDescription.map(
      s => this.translate.get(`SCORES.HINTS.${s}`).pipe(
        map((desc) => ({ key: s, title: '', desc }))
      ));

    const scoresDescWithTitle = scoreTooltipDescriptionWithTitle.map(
        s => combineLatest([
          this.translate.get(`SCORES.HINTS.${s}.TITLE`),
          this.translate.get(`SCORES.HINTS.${s}.DESC`)
        ]).pipe(
          map(([title, desc]) => ({ key: s, title, desc }))
        )
      );

    return combineLatest([
      ...scoresDesc,
      ...scoresDescWithTitle,
    ]).pipe(map((response) => {
      return response.reduce((acc, { key, title, desc }) => {
        return { ...acc, [key]: { title, desc } }
      }, {})
    }))
  }
}
