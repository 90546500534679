import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';

import { GlobalErrorHandlerService } from './global-error-handler.service';
import { InsightsService } from './insights.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
  InsightsService,
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandlerService
  }
  ],
})
export class InsightsModule { }
