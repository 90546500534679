import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationExtras, Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  private homeUrl: string = '/manage';
  private adminBoardUrl: string = '/manage/admin/configure/companies';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  public navigateToDashboard(isMaster: boolean): void {

    isMaster
    ? this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams.redirect || this.adminBoardUrl)
    : this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams.redirect || this.homeUrl);
  }

  public navigate(commands: any[], extras?: NavigationExtras): void {
    this.router.navigate(commands, extras)
  }

  public navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigateByUrl(url, extras)
  }

  public getDataFromResolver(): Observable<Data> {
    return this.activatedRoute.data;
  }

  public getRouterUrl(): string {
    return this.router.url;
  }
}
