import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'porcupine-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Input() title;
  @Input() message;

  @Input() useTranslation: boolean = false
  @Input() translateTitle: string | undefined;
  @Input() translateMessage: string | undefined;

  @Input() translateTitleParams: any = {};
  @Input() translateMessageParams: any = {};

  constructor(public modal: NgbActiveModal) { }
}
