import { IconType } from './icon-type';

export type IconAssetPath = string;

export const iconsConfigurationMap: Map<IconType, IconAssetPath> = new Map([
  [IconType.CIRCLE_CHEVRON_DOWN, './../../../../assets/svg/fontawesome/fal/circle-chevron-down.svg'],
  [IconType.SPEEDOMETR, './../../../../assets/svg/icons/speedometr.svg'],
  [IconType.UMBRELLA_WITH_DOLLAR, './../../../../assets/svg/icons/umbrella-with-dollar.svg'],
  [IconType.DOLLAR_UNDER_HAND, './../../../../assets/svg/icons/dollar-under-hand.svg'],
  [IconType.HUMAN, './../../../../assets/svg/icons/human.svg'],
  [IconType.HUMAN_LOUPE, './../../../../assets/svg/icons/human-loupe.svg'],
  [IconType.SYNC_DOLLAR, './../../../../assets/svg/icons/sync-dollar.svg'],
  [IconType.MENTAL_HEALTH, './../../../../assets/svg/icons/menthal-health.svg'],
  [IconType.LOCK, './../../../../assets/svg/icons/lock.svg'],
  [IconType.CHART_BAR, './../../../../assets/svg/icons/chart-bar.svg'],
  [IconType.LINE_DOT_CHART, './../../../../assets/svg/icons/line-dot-chart.svg'],
  [IconType.MICROPHONE, './../../../../assets/svg/icons/microphone.svg'],
]);
