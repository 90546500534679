import { Component, EventEmitter, Input, Output } from '@angular/core';

import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';

import { ContextSideMenuConfiguration } from '../../configurations/context-side-menu-configuration';
import { ContextSideNavigationType } from '../../configurations/context-side-navigation-type';

@Component({
  selector: 'porcupine-predictor-reports',
  templateUrl: './predictor-reports.component.html',
  styleUrls: ['./predictor-reports.component.scss']
})
export class PredictorReportsComponent {
  @Input() public isFolded: boolean;
  @Output() public linkClickEmitter$: EventEmitter<void> = new EventEmitter();

  public streamIdLink: string = '';
  public icons: any = { faChevronCircleDown };
  public configurationData: ContextSideMenuConfiguration;

  @Input() public set streamId(id: string) {
    if (this.configurationData.ids.streamId) return;
    this.streamIdLink = `/manage/admin/team/${id}`;
  }

  @Input() set configuration(configuration: ContextSideMenuConfiguration) {
    this.configurationData = configuration;
    switch(configuration.context) {
      case ContextSideNavigationType.PRODUCTS:
      case ContextSideNavigationType.REPORTS:
         this.setupDefaultConfiguration(configuration.ids);
         break;
      case ContextSideNavigationType.TEAM_REPORTS:
        this.setupTeamConfiguration(configuration.ids)
        break;
      case ContextSideNavigationType.RECORDER_REPORTS:
        this.setupRecorderConfiguration(configuration.ids)
        break;
      case ContextSideNavigationType.WELLNESS_REPORTS:
        this.setupWellnessConfiguration(configuration.ids)
        break;
      default:
          this.setupDefaultConfiguration(configuration.ids);
          break;
    }
  }

  public onClick(): void {
    if (window.innerWidth < 992) this.linkClickEmitter$.emit()
  }

  private setupDefaultConfiguration(ids: {streamId: string; userId: string}): any {
    if (!ids.streamId && !ids.userId) return;
    this.streamIdLink = `/manage/predictors/${ids.streamId}`;
  }

  private setupTeamConfiguration(ids: {streamId: string; userId: string}): any {
    if (!ids.streamId || !this.streamId) return;
    this.streamIdLink = `/manage/admin/team/${ids.streamId || this.streamId}`;
  }

  private setupRecorderConfiguration(ids: {streamId: string; userId: string}): any {
    if (!ids.streamId && !ids.userId) return;
    this.streamIdLink = `/manage/recorder/predictors/${ids.streamId}/user/${ids.userId}`;
  }

  private setupWellnessConfiguration(ids: {streamId: string; userId: string}): any {
    if (!ids.streamId && !ids.userId) return;
    this.streamIdLink = `/manage/wellness/predictors/${ids.streamId}/user/${ids.userId}`;
  }
}
