import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';

import { AuthorizationService } from './../authorization-service/authorization.service';

export enum HttpErrorStatusCode {
  'UNAUTHORIZED' = 401,
  'FORBIDDEN' = 403,
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private toastrService: ToastrService,
    private authorizationService: AuthorizationService,
    private translate: TranslateService,
  ){}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const notCatchingError: boolean = request.headers.has('catchError') && request.headers.get('catchError') === 'false';

    if (request.headers.has('catchError')) {
      request = request.clone({
          headers: request.headers.delete('catchError')
      });
    }

    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (notCatchingError) {
            return throwError(error);
          }

          if (error.error instanceof ErrorEvent) {
            return throwError(error);
          }
          else if (error.status === HttpErrorStatusCode.FORBIDDEN
            || error.status === HttpErrorStatusCode.UNAUTHORIZED) {
            this.authorizationService.signOut();

            return combineLatest([
              this.translate.get('GENERAL_ERRORS.TOKEN_EXPIRED_TITLE'),
              this.translate.get('GENERAL_ERRORS.TOKEN_EXPIRED_MSG'),
            ]).pipe(
              tap(([title, message]) => this.toastrService.error(message, title)),
              switchMap(() => of(error as any))
            );
          } else {
            return combineLatest([
              this.translate.get('GENERAL_ERRORS.GENERAL_TITLE'),
              this.translate.get('GENERAL_ERRORS.GENERAL_MSG'),
            ]).pipe(
              tap(([title, message]) => this.toastrService.error(message, title)),
              switchMap(() => throwError(error))
            );
          }
        })
      );
  }
}
