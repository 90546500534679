import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthorizationService } from 'src/app/services/authorization-service/authorization.service';
import { LocalStorageService } from 'src/app/shared/LocalStorageService';

import { EnvironmentService } from '../environment-service/environment.service';

const MILISECONDS_IN_MINUTE: number = 60_000;

@Injectable()
export class HttpRefreshTokenInterceptor implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService,
    private authorizationService: AuthorizationService,
    private environmentService: EnvironmentService){}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.shouldRefreshToken()) return next.handle(request);
    this.authorizationService.onRefreshToken = true;
    return this.authorizationService.refreshToken()
      .pipe(
        catchError(() => next.handle(request)),
        switchMap(() => {
        this.authorizationService.onRefreshToken = false;
        return next.handle(request)
      }));
  }

  private shouldRefreshToken(): boolean {
    if (!this.environmentService.getEnvironment()?.baseUrl
    || this.authorizationService.onRefreshToken) return false;

    const tokenExpirationDate: Date = new Date(this.localStorageService.get('tokenExpirationDate'));
    const now: Date = new Date();

    const minutesLeftToTokenExpire: number = this.milisecondsToMinute(tokenExpirationDate.getTime() - now.getTime())

    return minutesLeftToTokenExpire > 0 && minutesLeftToTokenExpire <= 10;
  }

  private milisecondsToMinute(miliseconds: number): number {
    return Math.floor(miliseconds / MILISECONDS_IN_MINUTE)
  }
}
