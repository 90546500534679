import { Component, Input } from '@angular/core';

import { SvgIcon } from 'src/app/shared/icon/components/svg/svg.component';

@Component({
  selector: 'porcupine-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent  {
  @Input() public faIcon: any;
  @Input() public svgIcon: SvgIcon | undefined;
  @Input() public headerTitle: string;

  @Input() public backArrowRedirect: string;
  @Input() public backArrowIcon: any;
}
