import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { faLink, faPrint, faQuestionCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';

import { CurrentRoles } from './core/components/side-navigation/manage-side-navigation.component';
import { WINDOW } from './providers/window.provider';
import { AuthorizationService, StorageEvent } from './services/authorization-service/authorization.service';
import { UserSession } from './services/authorization-service/user-session';
import { Environment, EnvironmentService } from './services/environment-service/environment.service';
import { InsightsService } from './services/insights-service/insights.service';
import { SideMenuService } from './services/side-menu-service/side-menu.service';
import { IconType } from './shared/icon/configuration/icon-type';
import { UserRole } from './shared/models/enums/user-role';
import { UserMenuConfiguration } from './shared/models/user-menu-configuration';
import { StoreService } from './shared/store.service';

import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'porcupine-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit, OnDestroy, AfterViewChecked {
  public logoUrl: string = `${this.environmentService.getEnvironment()?.blobStorageUrl}/templates/${this.window.location.hostname}-square-logo.png`;
  public iconType: typeof IconType =  IconType;
  public icons = {
    faUserCircle,
    faQuestionCircle,
    faPrint,
    faLink,
  }

  public isDemo$: Observable<boolean> = this.authorizationService.isDemo();
  public isMaster$: Observable<boolean> = this.authorizationService.isMaster();

  public currentRoles$: Observable<CurrentRoles> = combineLatest([
    this.authorizationService.isMaster(),
    this.authorizationService.isCompanyOperator(),
    this.authorizationService.isTeamOperator(),
    this.authorizationService.isPersonalRecorderOperator(),
    this.authorizationService.isWellenessOperator(),
  ]).pipe(
    map(([
      isMaster,
      isCompanyOperator,
      isTeamAdministrator,
      isPersonalRecorderOperator,
      isWellnessOperator]) => {
      return {
        isMaster,
        isCompanyOperator,
        isTeamAdministrator,
        isPersonalRecorderOperator,
        isWellnessOperator,
    }
    }));

  public userMenu$: Observable<UserMenuConfiguration[]> = this.authorizationService.getUserSession()
  .pipe(filter(Boolean), map(() =>
    this.userMenuConfiguration.get(UserRole.DEFAULT) as UserMenuConfiguration[]
  ));

  public activeStreamId$: Observable<string>;
  public userSession$: Observable<UserSession>;
  public year: number = new Date().getFullYear();

  public isContextMenuVisible$: Observable<boolean>;

  public environment: Environment = this.environmentService.getEnvironment();
  private styleElement: any;
  private untilDestroy$: Subject<void> = new Subject();

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    private environmentService: EnvironmentService,
    private insightsService: InsightsService,
    private store: StoreService,
    private authorizationService: AuthorizationService,
    private changeDetectorRef: ChangeDetectorRef,
    private sideMenuService: SideMenuService,
	private translate: TranslateService,
	private titleService: Title) {
      this.insightsService.startMonitoring();
    }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.setupSideNav();
  }

  @HostListener('window:storage', ['$event'])
  public onStorageChange(storeEvent: StorageEvent): void {
    this.authorizationService.handleMultiTab(storeEvent);
  }

  public ngOnInit(): void {
    this.createStyle();
    this.setupSideNav();
    this.userSession$ = this.authorizationService.getUserSession()
     .pipe(takeUntil(this.untilDestroy$));

    this.activeStreamId$ = this.store.getActiveStreamId();
  }

  public ngAfterViewChecked(): void {
    this.isContextMenuVisible$ = this.sideMenuService.isContextMenuDisplayed();
    this.changeDetectorRef.detectChanges();
	this.setupTitle();
  }

  public ngOnDestroy(): void {
    this.untilDestroy$.next();
    this.untilDestroy$.complete();

    if (this.styleElement) {
      this.renderer.removeChild(this.doc.head, this.styleElement);
    }
  }

  private userMenuConfiguration: Map<UserRole, UserMenuConfiguration[]> = new Map([
    [UserRole.DEFAULT, [{
      text: 'Log out',
      icon: 'lock',
      click: () => {
        this.authorizationService.signOut();
      }
    }]],
  ])

  private setupSideNav(): void {
    window.innerWidth < 992
     ? this.sideMenuService.collapseMenu(true)
     : this.sideMenuService.expandMenu(true)
  }

  private createStyle(): void {
    const blobUrl = this.environmentService.getEnvironment()?.blobStorageUrl;
    const hostname = this.window.location.hostname;
    this.styleElement = this.renderer.createElement('link');
    this.styleElement.rel = 'stylesheet';
    this.styleElement.href = `${blobUrl}/templates/${hostname}.css`;
    this.renderer.appendChild(this.doc.head, this.styleElement);
  }
  
  private setupTitle(): void {
    const blobUrl = this.environmentService.getEnvironment()?.blobStorageUrl;
    const hostname = this.window.location.hostname;
	let favIcon: HTMLLinkElement = document.querySelector('#favIcon'); 
	favIcon.href = `${blobUrl}/templates/${hostname}-favicon.png`;
    this.translate.get('WEBSITE_NAME').pipe(take(1)).subscribe((pageName) => this.titleService.setTitle(pageName));
  }
}
