import { Component, Input } from '@angular/core';

export enum SvgIcon {
  CIRCLE_CHEVRON_DOWN ='CIRCLE_CHEVRON_DOWN',
  CIRCLE_CHEVRON_UP ='CIRCLE_CHEVRON_UP',
  LINE_DOT_CHART ='LINE_DOT_CHART',
  COMMENT_PHONE ='COMMENT_PHONE',
  CALL_CENTER_CONSULTANT ='CALL_CENTER_CONSULTANT',
  HEARTBEAT_PULSE = 'HEARTBEAT_PULSE',
  CANDIDATE_SUCCESS = 'CANDIDATE_SUCCESS',
  COLLECTIONS = 'COLLECTIONS',
  EMPLOYEE_CHURN = 'EMPLOYEE_CHURN',
  LOAN_DEFAULT = 'LOAN_DEFAULT',
  PERSONALITY_ATTRIBUTE = 'PERSONALITY_ATTRIBUTE',
  SALES_CONVERSION = 'SALES_CONVERSION',
  WELLNESS = 'WELLNESS',
  MENTAL_HEALTH = 'MENTAL_HEALTH',
  MICROPHONE = 'MICROPHONE',
}

@Component({
  selector: 'porcupine-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent {
  @Input() public svgIcon: SvgIcon = SvgIcon.CIRCLE_CHEVRON_DOWN;
  @Input() public color: string;
  @Input() public width: number = 17.5;
  @Input() public height: number = 20;

  public svgIconType: typeof SvgIcon = SvgIcon;
}
