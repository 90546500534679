import { Component, Input } from '@angular/core'

import { IconAssetPath, iconsConfigurationMap } from '../../configuration/icons.configuration';
import { IconType } from './../../configuration/icon-type';

@Component({
  selector: 'porcupine-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  public iconSource: IconAssetPath = iconsConfigurationMap.get(IconType.SPEEDOMETR);

   @Input() public set iconType(type: IconType) {
     this.iconSource = iconsConfigurationMap.get(type)
   }
}
