export enum UserRole {
  DEFAULT ='Default',
  MASTER ='Master',
  DEMO ='Demo',
  COMPANY_OPERATOR ='Administrator',
  TEAM_OPERATOR ='Team Admin',
  WELLNESS_OPERATOR ='Wellness Operator',
  PERSONAL_RECORDER_OPERATOR ='Personal Recorder Operator',
  SERVICE_ACCOUNT ='ServiceAccount',
}
