import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, take, tap } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LocalStorageService } from 'src/app/shared/LocalStorageService';
import { UserRole } from 'src/app/shared/models/enums/user-role';

import { UserSession } from './user-session';

export enum NavigationLoaction {
  MANAGE_HOME = '/manage/home',
  MANAGE_ADMIN_TEAMS = '/manage/admin/teams',
  MANAGE_ADMIN_REPORTS = '/manage/admin/reports',
  WELLNESS_REPORTS = '/manage/wellness/reports',
  RECORDER_REPORTS = '/manage/recorder/reports'
}

export interface StorageEvent {
  key: string
  newValue: string
  oldValue: string
}

export enum StorageKey {
  BEARER_TOKEN = 'bearerToken',
  REFRESH_TOKEN ='refreshToken',
  TOKEN_EXPIRATION_DATE ='tokenExpirationDate',
  COMPANY_ID ='companyId',
  USER_ID ='userId',
}

const unauthorizedLocations: Set<string> = new Set(['/', '/user/password/change', '/forbidden'])

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  public userSession$: BehaviorSubject<UserSession> = new BehaviorSubject(null);
  public onRefreshToken: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService) {}

  public getTokenFromIdentityService(username: string, password: string, options: { [key:string]: string } = {}): Observable<UserSession> {
    const headers =  { 'content-type': 'application/json; charset=utf-8', ...options };
    const body = {
      userName: username,
      password: password
    };

    return this.http.post<UserSession>('/api/authenticate', body, { 'headers' : headers } )
    .pipe(
      tap((userSession: UserSession) => this.setupUserSessionVariableInLocalStorage(userSession)));
  }

  public getUserData(options: { [key:string]: string } = {}): Observable<any> {
    return this.http.get('/api/Authenticate', { 'headers': options });
  }

  public resetPassword(email: string): Observable<any> {
    return this.http.post(`/user/${email}/password/reset`, {});
  }

  public refreshToken(): Observable<UserSession> {
    return this.http.get<UserSession>('/api/authenticate/refreshtoken')
      .pipe(
        tap((userSession: UserSession) => this.setupUserSessionVariableInLocalStorage(userSession))
      );
  }

  public checkAuth(): void {
    localStorage.getItem('bearerToken')
    ? this.signInByToken().subscribe(
      (data) => this.signIn(data),
      () => this.signOut())
    : this.signOut();
  }

  public signInByToken(options: any = {}): Observable<any> {
    return this.getUserData(options)
    .pipe(take(1))
  }

  public signIn(userSession: UserSession): void {
    if (this.router.url.includes('/user/password/change')) return;
    this.setUserSession(userSession);
  }

  public signOut(redirect: boolean = true, clearStorage: boolean = true): void {
    clearStorage && this.clearLocalStorage()
    this.setUserSession(null);

    if (redirect && !unauthorizedLocations.has(this.router.url)) {
      this.modalService.dismissAll();
      this.router.navigateByUrl('/login');
    }
  }

  public getUserSession(): Observable<UserSession> {
    return this.userSession$.asObservable().pipe(shareReplay(1));
  }

  public setUserSession(userSession: UserSession): void {
   this.userSession$.next(userSession);
  }

  public isTeamOperator(): Observable<boolean> {
    return this.getUserSession().pipe(map((session: UserSession) =>  !!session?.roles?.find((role) => role.endsWith(UserRole.TEAM_OPERATOR))))
  }

  public isMaster(): Observable<boolean> {
    return this.getUserSession().pipe(map((session: UserSession) =>  !!session?.roles?.find((role) => role.endsWith(UserRole.MASTER))))
  }

  public isCompanyOperator(): Observable<boolean> {
    return this.getUserSession().pipe(map((session: UserSession) => session?.roles?.includes(UserRole.COMPANY_OPERATOR)))
  }

  public isWellenessOperator(): Observable<boolean> {
    return this.getUserSession().pipe(map((session: UserSession) => session?.roles?.includes(UserRole.WELLNESS_OPERATOR)))
  }

  public isPersonalRecorderOperator(): Observable<boolean> {
    return this.getUserSession().pipe(map((session: UserSession) => session?.roles?.includes(UserRole.PERSONAL_RECORDER_OPERATOR)))
  }

  public isDemo(): Observable<boolean> {
    return this.getUserSession().pipe(map((session: UserSession) => session?.roles?.includes(UserRole.DEMO)))
  }

  public handleMultiTab(storeEvent: StorageEvent): void {
    if (storeEvent.key === StorageKey.BEARER_TOKEN && storeEvent.newValue === null) this.signOut();
    if (storeEvent.key === StorageKey.USER_ID 
    && storeEvent.oldValue !== null
    && storeEvent.oldValue !== storeEvent.newValue) {
      this.signOut(true, false);
    }
  }

  private setupUserSessionVariableInLocalStorage(session: UserSession): void {
    this.localStorageService.set(StorageKey.BEARER_TOKEN, session.bearerToken);
    this.localStorageService.set(StorageKey.REFRESH_TOKEN, session.refreshToken);
    this.localStorageService.set(StorageKey.TOKEN_EXPIRATION_DATE, session.tokenExpirationDate);
    this.localStorageService.set(StorageKey.COMPANY_ID, session.companyId);
    this.localStorageService.set(StorageKey.USER_ID, session.userId);
  }

  private clearLocalStorage(): void {
    this.localStorageService.remove(StorageKey.BEARER_TOKEN);
    this.localStorageService.remove(StorageKey.REFRESH_TOKEN);
    this.localStorageService.remove(StorageKey.TOKEN_EXPIRATION_DATE);
    this.localStorageService.remove(StorageKey.COMPANY_ID);
    this.localStorageService.remove(StorageKey.USER_ID);
  }
}
