import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';

import { faPrint, faQuestionCircle, faUserCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { WINDOW } from 'src/app/providers/window.provider';
import { EnvironmentService } from 'src/app/services/environment-service/environment.service';
import { LanguageService } from 'src/app/services/language-service/language.service';
import { ProductType } from 'src/app/shared/models/enums/product-type.enum';

import { IconType } from '../../../shared/icon/configuration/icon-type';
import { UserMenuConfiguration } from '../../../shared/models/user-menu-configuration';
import { StoreService } from '../../../shared/store.service';
import { UserSession } from './../../../services/authorization-service/user-session';
import { PdfService } from './../../../services/pdf-service/pdf.service';

const graphReports = [
  ProductType.WELLNESS,
  ProductType.MENTAL_HEALTH,
  ProductType.LOAN_DEFAULT,
  ProductType.COLLECTIONS,
  ProductType.CANDIDATE_SUCCESS,
  ProductType.EMPLOYEE_CHURN,
  ProductType.SALES_CONVERSION,
  ProductType.PERSONALITY_ATTRIBUTE,
  ProductType.DASHBOARD,
  ProductType.CALL_ANALYZER,
  ProductType.MOBILE_TRADING,
  ProductType.RECRUIT,
  ProductType.FRAUD_PREDICTOR,
  ProductType.CUSTOM,
]

@Component({
  selector: 'porcupine-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent {
  @Input() public userMenu: UserMenuConfiguration[] = [];
  @Input() public userSession: UserSession | undefined;
  private initial = true;
  public styles = {
    '.action-icon.error': {
      'color': 'red'
    },
    'table': {
      'table-layout': 'fixed',
      'font-family': 'arial, sans-serif',
      'border-collapse': 'collapse',
      'width': '100%',
      'font-size': '12px',
      'display': 'table',
      'box-sizing': 'border-box',
      'text-indent': 'initial',
      'empty-cells': 'show'
    },
    'thead': {
      'display': 'table-header-group',
      'border-color': 'inherit'
    },
    'tbody': {
      'display': 'table-row-group',
      'vertical-align': 'middle',
      'border-color': 'inherit'
    },
    'th': {
      'display': 'table-cell',
      'vertical-align': 'inherit',
      'font-weight': 'bold',
      'border': '1px solid #dddddd',
      'text-align': 'left',

      'white-space': 'nowrap',
      'overflow': 'hidden',
      'text-overflow': 'ellipsis'
    },
    'td': {
      'display': 'table-cell',
      'border': '1px solid #dddddd',
      'text-align': 'left',
      'padding': '8px',
      'min-width': '50px!important',
      'overflow': 'hidden'
    },
    'tr:nth-child(even)': {
      'display': 'table-row',
      'background-color': '#dddddd'
    },
    'svg': {
      'width': '10px'
    },
    '.k-pager-numbers .k-link.k-state-selected': {
      'color': 'blue'
    },
    'a': {
      'border-color': '#dee2e6',
      'color': '#007bff',
      'background-color': '#ffffff'
    },
    '.k-grid-pager': {
      'border-color': '#dee2e6',
      'color': '#212529',
      'background-color': '#f8f9fa'
    },
    'kendo-pager': {
      'display': 'flex',
      'flex': '1',
      'justify-content': 'center',
      'align-items': 'center',
      'gap': '20px'
    },
    'kendo-pager-numeric-buttons ul': {
      'display': 'flex',
      'flex': '1',
      'width': '100%',
      'justify-content': 'center',
      'align-items': 'center',
      'gap': '10px',
      'list-style-type': 'none'
    },
    'k-state-selected': {
      'background-color': 'grey'
    },
    'div[role=\'presentation\']': {
      'padding': '0!important'
    }
  }

  public printableElement$: Observable<any> =
    this.pdfService.getPrintableElement().pipe(
      distinctUntilChanged(),
      map(v => {
        const existingCss = graphReports.includes(v as ProductType)
        return v ? { title: `Report-${v}`, existingCss, styles: {
          ...this.styles,
        } } : null
      }
    ));

  public logoUrl: string = `${this.environmentService.getEnvironment()?.blobStorageUrl}/templates/${this.window.location.hostname}-logo-app.png`;
  public iconType: typeof IconType = IconType;
  public productType: typeof ProductType = ProductType;

  public icons: { [key:string]: IconDefinition } = {
    faQuestionCircle,
    faUserCircle,
    faPrint
  }

  public languages$ = this.languageService.getLanguagesDropdown().pipe(
    take(1),
    tap((l) => {
      if (this.initial) {
        const initialValue = l.find(({ value }) => value === this.languageService.getCurrentLanguage());
        if (initialValue) this.languageForm.controls.language.patchValue(initialValue);
        this.initial = false;
      }
    })
  );

  public languageForm = new FormGroup({
    language: new FormControl({ text: 'English', value: 'en' })
  })

  constructor(
    @Inject(WINDOW) private window: Window,
    private store: StoreService,
    private pdfService: PdfService,
    private languageService: LanguageService,
    private environmentService: EnvironmentService,
  ) {
    this.languageForm.controls.language.valueChanges.subscribe(({ value }) => {
      this.languageService.setLanguage(value);
    })
  }

  public back(): void {
    this.store.clearActiveStreamAndGoDashboard();
  }
}
