import { NavigationLoaction } from 'src/app/services/authorization-service/authorization.service';

import { ContextSideNavigationType } from './context-side-navigation-type';

export interface ContextSideMenuConfiguration {
  [key: string]: any
}

export const contextSideNavigationConfiguration: Map<ContextSideNavigationType, ContextSideMenuConfiguration> = new Map([
  [ContextSideNavigationType.DEFAULT, {
    context: ContextSideNavigationType.DEFAULT,
    title: 'reports',
    isFeaturesVisible: true,
    backNavigation: NavigationLoaction.MANAGE_HOME,
    dashboardLink: ContextSideNavigationType.DEFAULT,
  }],
  [ContextSideNavigationType.PRODUCTS, {
    context: ContextSideNavigationType.DEFAULT,
    title: 'reports',
    isFeaturesVisible: true,
    backNavigation: NavigationLoaction.MANAGE_HOME,
    dashboardLink: ContextSideNavigationType.PRODUCTS,
  }],
  [ContextSideNavigationType.TEAM_REPORTS, {
    context: ContextSideNavigationType.TEAM_REPORTS,
    title: 'teamReports',
    isFeaturesVisible: true,
    backNavigation: NavigationLoaction.MANAGE_ADMIN_TEAMS,
    dashboardLink: ContextSideNavigationType.TEAM_REPORTS,
  }],
  [ContextSideNavigationType.REPORTS, {
    context: ContextSideNavigationType.DEFAULT,
    title: 'reports',
    isFeaturesVisible: true,
    backNavigation: NavigationLoaction.MANAGE_ADMIN_REPORTS,
    dashboardLink: ContextSideNavigationType.REPORTS,
  }],
    [ContextSideNavigationType.WELLNESS_REPORTS, {
    context: ContextSideNavigationType.WELLNESS_REPORTS,
    title: 'reports',
    isFeaturesVisible: true,
    backNavigation: NavigationLoaction.WELLNESS_REPORTS,
    dashboardLink: ContextSideNavigationType.WELLNESS_REPORTS,
  }],
  [ContextSideNavigationType.RECORDER_REPORTS, {
    context: ContextSideNavigationType.RECORDER_REPORTS,
    title: 'reports',
    isFeaturesVisible: true,
    backNavigation: NavigationLoaction.RECORDER_REPORTS,
    dashboardLink: ContextSideNavigationType.RECORDER_REPORTS,
  }],
])
