import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { IconModule } from '../icon/icon.module';
import { PredictorReportsComponent } from './components/predictor-reports/predictor-reports.component';
import { ContextSideNavigationComponent } from './context-side-navigation/context-side-navigation.component';

@NgModule({
  declarations: [
    ContextSideNavigationComponent,
    PredictorReportsComponent,
  ],
  exports: [ContextSideNavigationComponent],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    IconModule
  ]
})
export class ContextSideNavigationModule { }
