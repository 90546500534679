import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

interface TelephonyDemoResponse {
    success?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DemoService {

  constructor(private http: HttpClient) { }

  public get(): Observable<DemosResponse>
  {
    return this.http.get<DemosResponse>('/demos');
  }

  public startDemo(id: string): Observable<TelephonyDemoResponse>
  {
    return this.http.post<TelephonyDemoResponse>(`/demos/telephony/${id}`, { id });
  }
}
