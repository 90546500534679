import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { ReportDescriptionService } from '../../reports/services/report-description.service';
import { ContextSideMenuConfiguration, contextSideNavigationConfiguration } from '../configurations/context-side-menu-configuration';
import { ContextSideNavigationType } from '../configurations/context-side-navigation-type';

@Injectable({
  providedIn: 'root'
})
export class ContextSideNavigationService {
  private configuration$: Subject<ContextSideMenuConfiguration> = new BehaviorSubject({});

  constructor(private reportDescriptionService: ReportDescriptionService) {
    this.setConfiguration()
  }

  public getConfiguration(): Observable<ContextSideMenuConfiguration>{
    return this.configuration$.asObservable()
  }

  public setConfiguration(configurationType: ContextSideNavigationType = ContextSideNavigationType.DEFAULT): void {
    this.reportDescriptionService.getConfTranslation().pipe(take(1)).subscribe(({ reports, teamReport }) => {
      const conf = {
        ...contextSideNavigationConfiguration.get(configurationType),
        title: contextSideNavigationConfiguration.get(configurationType).title === 'teamReports' ? teamReport : reports
      }
      this.configuration$.next(conf);
    })

  }
}
