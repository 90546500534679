import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { UserSession } from '../services/authorization-service/user-session';
import { LocalStorageService } from '../shared/LocalStorageService';
import { StoreService } from '../shared/store.service';
import { AuthorizationService } from './../services/authorization-service/authorization.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private storeService: StoreService,
    private authorizationService: AuthorizationService,
    private localStorageService: LocalStorageService,
    private router: Router) {}

  canActivate(): Observable<boolean> | boolean {
    const token: string = this.localStorageService.get('bearerToken');
    const userSession: any = this.authorizationService.userSession$.getValue();

    if (!token) this.goLoginPage();
    if (userSession) return true;
    else {
      return this.authorizationService.signInByToken({ catchError: 'false' })
      .pipe(
        take(1),
        catchError(() => {
          this.authorizationService.signOut(false);
          return of(this.goLoginPage());
        }),
        map((userData: UserSession) => {
          if (userData) {
            this.authorizationService.signIn(userData);
            return true;
          }
          else return false;
        })
      )
    }
  }

  private goLoginPage(): boolean {
    this.router.navigate(['/login'])
    return false;
  }
}
