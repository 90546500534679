export enum ProductType {
  WELLNESS = 'wellness',
  MENTAL_HEALTH = 'mental-health',
  LOAN_DEFAULT = 'loan-default',
  COLLECTIONS = 'collections',
  CANDIDATE_SUCCESS = 'candidate-success',
  EMPLOYEE_CHURN = 'employee-churn',
  SALES_CONVERSION = 'sales-conversion',
  PERSONALITY_ATTRIBUTE = 'personality-attribute',
  DASHBOARD = 'dashboard',
  CALL_ANALYZER = 'call-analyzer',
  MOBILE_TRADING = 'mobile-trading',
  RECRUIT = 'recruit',
  FRAUD_PREDICTOR = 'fraud-predictor',
  CUSTOM = 'custom-product'
}
