import { Component } from '@angular/core';

import { Subscription } from 'rxjs';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DemoService } from '../../services/demo.service';

@Component({
  selector: 'porcupine-demo-select-modal',
  templateUrl: './demo-select-modal.component.html',
  styleUrls: ['./demo-select-modal.component.scss']
})
export class DemoSelectModalComponent {

  sub: Subscription;
  demos: Demo[];

  constructor(
    private activeModal: NgbActiveModal,
    private demoService: DemoService)
  {
    this.sub = this.demoService.get().subscribe((response: DemosResponse) => {
      this.demos = response.demos;
    });
  }

  public close(): void {
    this.activeModal.dismiss();
  }

  public startDemo(selectedDemo: Demo): void{
    this.activeModal.close(selectedDemo);
  }

}
