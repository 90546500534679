import { Score } from './score.enum';
import { ScoreType } from './score-type';

export interface TooltipContent {
 desc: string;
 title?: string;
}

export const scoreTooltipDescription: ScoreType[] = [
  ScoreType.CANDIDATE_SUCCESS_OVERALL,
  ScoreType.CANDIDATE_SUCCESS_TEMPERAMENT,
  ScoreType.CANDIDATE_SUCCESS_AMBITION,
  ScoreType.CANDIDATE_SUCCESS_COMMUNICATION,
  ScoreType.CANDIDATE_SUCCESS_COOPERATION,
  ScoreType.CANDIDATE_SUCCESS_SYSTEMATIC,
  ScoreType.CANDIDATE_SUCCESS_INTEGRITY,
  ScoreType.CANDIDATE_SUCCESS_ADJUSTMENT,
  ScoreType.CANDIDATE_SUCCESS_INNOVATION,
  ScoreType.CANDIDATE_SUCCESS_CREATIVITY,
  ScoreType.CANDIDATE_SUCCESS_LEARNING,
  ScoreType.CANDIDATE_SUCCESS_EXTRAVERSION,
  ScoreType.CANDIDATE_SUCCESS_AGREEABLENESS,
  ScoreType.CANDIDATE_SUCCESS_CONSCIENTIOUSNESS,
  ScoreType.CANDIDATE_SUCCESS_EMOTIONAL_STABILITY,
  ScoreType.CANDIDATE_SUCCESS_OPENNESS,
  ScoreType.CANDIDATE_SUCCESS_SALES_FIT,
  ScoreType.CANDIDATE_SUCCESS_MANAGEMENT_FIT,
  ScoreType.CANDIDATE_SUCCESS_CUSTOMER_SERVICE_FIT,
  ScoreType.CANDIDATE_SUCCESS_RDFIT,
  ScoreType.COLLECTION_OVERALL,
  ScoreType.COLLECTION_COOPERATION,
  ScoreType.COLLECTION_EMOTIONAL_STABILITY,
  ScoreType.COLLECTION_ENERGY_LEVEL,
  ScoreType.COLLECTION_BURNOUT,
  ScoreType.COLLECTION_PRICE_VALUE_CONSUMER,
  ScoreType.COLLECTION_UNREALISTIC,
  ScoreType.DASHBOARD_SATISFACTION_CALLER,
  ScoreType.DASHBOARD_ENGAGED_CALLER,
  ScoreType.DASHBOARD_ATTITUDE_LEVEL_CALLER,
  ScoreType.DASHBOARD_ATTITUDE_LEVEL_AGENT,
  ScoreType.DASHBOARD_ENGAGED_AGENT,
  ScoreType.DASHBOARD_ATTENTIVE_AGENT,
  ScoreType.DASHBOARD_OPEN_AGENT,
  ScoreType.EMPLOYEE_CHURN_ATTRITION,
  ScoreType.EMPLOYEE_CHURN_OVERALL_WELLBEING,
  ScoreType.EMPLOYEE_CHURN_WB_ENERGY,
  ScoreType.EMPLOYEE_CHURN_WB_STRESS_CONTROL,
  ScoreType.EMPLOYEE_CHURN_WB_EMOTION,
  ScoreType.EMPLOYEE_CHURN_WB_SOCIAL,
  ScoreType.EMPLOYEE_CHURN_WB_COPING,
  ScoreType.EMPLOYEE_CHURN_COOPERATION,
  ScoreType.EMPLOYEE_CHURN_EMOTIONAL_STABILITY,
  ScoreType.EMPLOYEE_CHURN_CONSCIENTIOUSNESS,
  ScoreType.EMPLOYEE_CHURN_BRAND_LOYAL_CONSUMER,
  ScoreType.EMPLOYEE_CHURN_BURNOUT,
  ScoreType.EMPLOYEE_CHURN_PRICE_VALUE_CONSUMER,
  ScoreType.LOAN_DEFAULT_OVERALL,
  ScoreType.LOAN_DEFAULT_COOPERATION,
  ScoreType.LOAN_DEFAULT_EMOTIONAL_STABILITY,
  ScoreType.LOAN_DEFAULT_ENERGY_LEVEL,
  ScoreType.LOAN_DEFAULT_BURNOUT,
  ScoreType.LOAN_DEFAULT_PRICE_VALUE_CONSUMER,
  ScoreType.LOAN_DEFAULT_OPEN,
  ScoreType.MATCH_MAKING_DETERMINED,
  ScoreType.MATCH_MAKING_DEPENDABLE,
  ScoreType.MATCH_MAKING_COMMUNICATIVE,
  ScoreType.MATCH_MAKING_ATTENTIVE,
  ScoreType.MATCH_MAKING_ADAPTIVE,
  ScoreType.MATCH_MAKING_OPENNESS,
  ScoreType.MATCH_MAKING_ORDERED,
  ScoreType.MATCH_MAKING_EMOTIONAL_STABILITY,
  ScoreType.PERSONALITY_ATTRIBUTE_ENGAGED,
  ScoreType.PERSONALITY_ATTRIBUTE_DETERMINED,
  ScoreType.PERSONALITY_ATTRIBUTE_DEPENDABLE,
  ScoreType.PERSONALITY_ATTRIBUTE_COMMUNICATIVE,
  ScoreType.PERSONALITY_ATTRIBUTE_ATTENTIVE,
  ScoreType.PERSONALITY_ATTRIBUTE_ADAPTIVE,
  ScoreType.PERSONALITY_ATTRIBUTE_CREATIVITY,
  ScoreType.PERSONALITY_ATTRIBUTE_ORDERED,
  ScoreType.PERSONALITY_ATTRIBUTE_EXTRAVERSION,
  ScoreType.PERSONALITY_ATTRIBUTE_AGREEABLENESS,
  ScoreType.PERSONALITY_ATTRIBUTE_CONSCIENTIOUSNESS,
  ScoreType.PERSONALITY_ATTRIBUTE_EMOTIONAL_STABILITY,
  ScoreType.PERSONALITY_ATTRIBUTE_OPENNESS,
  ScoreType.SALES_CONVERSION_OVERALL,
  ScoreType.SALES_CONVERSION_PERFECTIONIST_CONSUMER,
  ScoreType.SALES_CONVERSION_BRAND_LOYAL_CONSUMER,
  ScoreType.SALES_CONVERSION_IMPULSIVE_CONSUMER,
  ScoreType.SALES_CONVERSION_HEDONISTIC_CONSUMER,
  ScoreType.SALES_CONVERSION_PRICE_VALUE_CONSUMER,
  ScoreType.WELLNESS_ASSESMENT_WB_ENERGY,
  ScoreType.WELLNESS_ASSESMENT_WB_SOCIAL,
  ScoreType.WELLNESS_ASSESMENT_WB_COPING,
  ScoreType.WELLNESS_ASSESMENT_GENERAL_WELLNESS,
  ScoreType.WELLNESS_ASSESMENT_WB_EMOTION,
  ScoreType.WELLNESS_ASSESMENT_WB_STRESS_CONTROL,
  ScoreType.WELLNESS_MENTAL_HEALTH_WB_ENERGY,
  ScoreType.WELLNESS_MENTAL_HEALTH_WB_STRESS_CONTROL,
  ScoreType.WELLNESS_MENTAL_HEALTH_WB_EMOTION,
  ScoreType.WELLNESS_MENTAL_HEALTH_WB_SOCIAL,
  ScoreType.WELLNESS_MENTAL_HEALTH_WB_COPING,
 ];

 export const scoreTooltipDescriptionWithTitle = [
  ScoreType.WELLNESS_MENTAL_HEALTH_GENERAL_WELLNESS,
  ScoreType.WELLNESS_MENTAL_HEALTH_DEPPRESSION,
  ScoreType.WELLNESS_MENTAL_HEALTH_BURNOUT,
  ScoreType.WELLNESS_ASSESMENT_GENERAL_WELLNESS,
  ScoreType.WELLNESS_MENTAL_HEALTH_ATTRITION,
 ];

 export const scores: Score[] = [
  Score.TEMPERAMENT,
  Score.ENGAGED,
  Score.DETERMINED,
  Score.DEPENDABLE,
  Score.COMMUNICATIVE,
  Score.ATTENTIVE,
  Score.ADAPTIVE,
  Score.OPEN,
  Score.WBENERGY,
  Score.WBEMOTION,
  Score.WBSOCIAL,
  Score.OVERALLWELLBEING,
  Score.WBCOPING,
  Score.ORDERED,
  Score.WBSTRESSCONTROL,
  Score.ENERGYLEVEL,
  Score.AMBITION,
  Score.INTEGRITY,
  Score.SYSTEMATIC,
  Score.COMMUNICATION,
  Score.INNOVATION,
  Score.COOPERATION,
  Score.ADJUSTMENT,
  Score.CREATIVITY,
  Score.FINANCIALRISK,
  Score.SALESFIT,
  Score.MANAGEMENTFIT,
  Score.CUSTOMERSERVICEFIT,
  Score.RDFIT,
  Score.LEARNING,
  Score.ATTRITION,
  Score.BURNOUT,
  Score.DISSATISFACTION,
  Score.UPSELLPROBABILITY,
  Score.DEBTREPAYMENTPROBABILITY,
  Score.PERFECTIONISTCONSUMER,
  Score.BRANDLOYALCONSUMER,
  Score.IMPULSIVECONSUMER,
  Score.HEDONISTICCONSUMER,
  Score.PRICEVALUECONSUMER,
  Score.AGREEABLENESS,
  Score.EXTRAVERSION,
  Score.CONSCIENTIOUSNESS,
  Score.EMOTIONALSTABILITY,
  Score.OPENNESS,
  Score.ATTITUDELEVEL,
  Score.SATISFACTION,
  Score.DEPRESSION,
  Score.TOADDFREECONVERSIONPROBABILITY,
  Score.TOVIPCONVERSIONPROBABILITY,
  Score.OVERALLWELLBEING10SCALE,
  Score.WBSTRESSCONTROL10SCALE,
  Score.WBENERGY10SCALE,
  Score.WBEMOTION10SCALE,
  Score.WBSOCIAL10SCALE,
  Score.WBCOPING10SCALE,
  Score.VOLUNTARYCHURN,
  Score.NONVOLUNTARYCHURN,
  Score.AGVOLUNTARYCHURN,
  Score.FRAUD,
 ]
