import { NgModule } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { NotifyPopupComponent } from './notify-popup.component';

@NgModule({
  declarations: [NotifyPopupComponent],
  imports: [FontAwesomeModule, NgbPopoverModule, TranslateModule],
  exports: [NotifyPopupComponent]
})
export class NotifyPopupModule { }
