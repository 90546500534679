import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgComponent } from './components/svg/svg.component';
import { IconComponent } from './container/icon/icon.component';

@NgModule({
  declarations: [IconComponent, SvgComponent],
  exports: [IconComponent, SvgComponent],
  imports: [
    CommonModule
  ]
})
export class IconModule { }
