import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface Credentials {
  email: string;
  password: string;
}

export interface Environment {
  production: boolean;
  buildId: string;
  buildNumber: string;
  sourceVersion: string;
  recorderApiUrl: string;
  createdLinkUrl: string;
  licenseServiceUrl: string;
  webhookServiceUrl: string;
  analysisServiceUrl: string;
  baseUrl: string;
  blobStorageUrl: string;
  insights: {
    enableTracing: boolean;
    instrumentationKey: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private environment: Environment;

  constructor(private http: HttpClient) {}

  public getEnvironment(): Environment {
    return this.environment;
  }

  public loadConfig(): Promise<void> {
    return this.http
      .get<Environment>('./assets/configuration/environment.json')
      .toPromise()
      .then(environment => {
        this.environment = environment;
      });
  }
}
