export enum ScoreType {
  CANDIDATE_SUCCESS_OVERALL = 'CANDIDATE_SUCCESS_OVERALL',
  CANDIDATE_SUCCESS_TEMPERAMENT = 'CANDIDATE_SUCCESS_TEMPERAMENT',
  CANDIDATE_SUCCESS_AMBITION = 'CANDIDATE_SUCCESS_AMBITION',
  CANDIDATE_SUCCESS_COMMUNICATION = 'CANDIDATE_SUCCESS_COMMUNICATION',
  CANDIDATE_SUCCESS_COOPERATION = 'CANDIDATE_SUCCESS_COOPERATION',
  CANDIDATE_SUCCESS_SYSTEMATIC = 'CANDIDATE_SUCCESS_SYSTEMATIC',
  CANDIDATE_SUCCESS_INTEGRITY = 'CANDIDATE_SUCCESS_INTEGRITY',
  CANDIDATE_SUCCESS_ADJUSTMENT = 'CANDIDATE_SUCCESS_ADJUSTMENT',
  CANDIDATE_SUCCESS_INNOVATION = 'CANDIDATE_SUCCESS_INNOVATION',
  CANDIDATE_SUCCESS_CREATIVITY = 'CANDIDATE_SUCCESS_CREATIVITY',
  CANDIDATE_SUCCESS_LEARNING = 'CANDIDATE_SUCCESS_LEARNING',
  CANDIDATE_SUCCESS_EXTRAVERSION = 'CANDIDATE_SUCCESS_EXTRAVERSION',
  CANDIDATE_SUCCESS_AGREEABLENESS = 'CANDIDATE_SUCCESS_AGREEABLENESS',
  CANDIDATE_SUCCESS_CONSCIENTIOUSNESS = 'CANDIDATE_SUCCESS_CONSCIENTIOUSNESS',
  CANDIDATE_SUCCESS_EMOTIONAL_STABILITY = 'CANDIDATE_SUCCESS_EMOTIONAL_STABILITY',
  CANDIDATE_SUCCESS_OPENNESS = 'CANDIDATE_SUCCESS_OPENNESS',
  CANDIDATE_SUCCESS_SALES_FIT = 'CANDIDATE_SUCCESS_SALES_FIT',
  CANDIDATE_SUCCESS_MANAGEMENT_FIT = 'CANDIDATE_SUCCESS_MANAGEMENT_FIT',
  CANDIDATE_SUCCESS_CUSTOMER_SERVICE_FIT = 'CANDIDATE_SUCCESS_CUSTOMER_SERVICE_FIT',
  CANDIDATE_SUCCESS_RDFIT = 'CANDIDATE_SUCCESS_RDFIT',
  COLLECTION_OVERALL = 'COLLECTION_OVERALL',
  COLLECTION_COOPERATION = 'COLLECTION_COOPERATION',
  COLLECTION_EMOTIONAL_STABILITY = 'COLLECTION_EMOTIONAL_STABILITY',
  COLLECTION_ENERGY_LEVEL = 'COLLECTION_ENERGY_LEVEL',
  COLLECTION_BURNOUT = 'COLLECTION_BURNOUT',
  COLLECTION_PRICE_VALUE_CONSUMER = 'COLLECTION_PRICE_VALUE_CONSUMER',
  COLLECTION_UNREALISTIC = 'COLLECTION_UNREALISTIC',
  DASHBOARD_SATISFACTION_CALLER = 'DASHBOARD_SATISFACTION_CALLER',
  DASHBOARD_ENGAGED_CALLER = 'DASHBOARD_ENGAGED_CALLER',
  DASHBOARD_ATTITUDE_LEVEL_CALLER = 'DASHBOARD_ATTITUDE_LEVEL_CALLER',
  DASHBOARD_ATTITUDE_LEVEL_AGENT = 'DASHBOARD_ATTITUDE_LEVEL_AGENT',
  DASHBOARD_ENGAGED_AGENT = 'DASHBOARD_ENGAGED_AGENT',
  DASHBOARD_ATTENTIVE_AGENT = 'DASHBOARD_ATTENTIVE_AGENT',
  DASHBOARD_OPEN_AGENT = 'DASHBOARD_OPEN_AGENT',
  EMPLOYEE_CHURN_ATTRITION = 'EMPLOYEE_CHURN_ATTRITION',
  EMPLOYEE_CHURN_OVERALL_WELLBEING = 'EMPLOYEE_CHURN_OVERALL_WELLBEING',
  EMPLOYEE_CHURN_WB_ENERGY = 'EMPLOYEE_CHURN_WB_ENERGY',
  EMPLOYEE_CHURN_WB_STRESS_CONTROL = 'EMPLOYEE_CHURN_WB_STRESS_CONTROL',
  EMPLOYEE_CHURN_WB_EMOTION = 'EMPLOYEE_CHURN_WB_EMOTION',
  EMPLOYEE_CHURN_WB_SOCIAL = 'EMPLOYEE_CHURN_WB_SOCIAL',
  EMPLOYEE_CHURN_WB_COPING = 'EMPLOYEE_CHURN_WB_COPING',
  EMPLOYEE_CHURN_COOPERATION = 'EMPLOYEE_CHURN_COOPERATION',
  EMPLOYEE_CHURN_EMOTIONAL_STABILITY = 'EMPLOYEE_CHURN_EMOTIONAL_STABILITY',
  EMPLOYEE_CHURN_CONSCIENTIOUSNESS = 'EMPLOYEE_CHURN_CONSCIENTIOUSNESS',
  EMPLOYEE_CHURN_BRAND_LOYAL_CONSUMER = 'EMPLOYEE_CHURN_BRAND_LOYAL_CONSUMER',
  EMPLOYEE_CHURN_BURNOUT = 'EMPLOYEE_CHURN_BURNOUT',
  EMPLOYEE_CHURN_PRICE_VALUE_CONSUMER = 'EMPLOYEE_CHURN_PRICE_VALUE_CONSUMER',
  LOAN_DEFAULT_OVERALL = 'LOAN_DEFAULT_OVERALL',
  LOAN_DEFAULT_COOPERATION = 'LOAN_DEFAULT_COOPERATION',
  LOAN_DEFAULT_EMOTIONAL_STABILITY = 'LOAN_DEFAULT_EMOTIONAL_STABILITY',
  LOAN_DEFAULT_ENERGY_LEVEL = 'LOAN_DEFAULT_ENERGY_LEVEL',
  LOAN_DEFAULT_BURNOUT = 'LOAN_DEFAULT_BURNOUT',
  LOAN_DEFAULT_PRICE_VALUE_CONSUMER = 'LOAN_DEFAULT_PRICE_VALUE_CONSUMER',
  LOAN_DEFAULT_OPEN = 'LOAN_DEFAULT_OPEN',
  MATCH_MAKING_DETERMINED = 'MATCH_MAKING_DETERMINED',
  MATCH_MAKING_DEPENDABLE = 'MATCH_MAKING_DEPENDABLE',
  MATCH_MAKING_COMMUNICATIVE = 'MATCH_MAKING_COMMUNICATIVE',
  MATCH_MAKING_ATTENTIVE = 'MATCH_MAKING_ATTENTIVE',
  MATCH_MAKING_ADAPTIVE = 'MATCH_MAKING_ADAPTIVE',
  MATCH_MAKING_OPENNESS = 'MATCH_MAKING_OPENNESS',
  MATCH_MAKING_ORDERED = 'MATCH_MAKING_ORDERED',
  MATCH_MAKING_EMOTIONAL_STABILITY = 'MATCH_MAKING_EMOTIONAL_STABILITY',
  PERSONALITY_ATTRIBUTE_ENGAGED = 'PERSONALITY_ATTRIBUTE_ENGAGED',
  PERSONALITY_ATTRIBUTE_DETERMINED = 'PERSONALITY_ATTRIBUTE_DETERMINED',
  PERSONALITY_ATTRIBUTE_DEPENDABLE = 'PERSONALITY_ATTRIBUTE_DEPENDABLE',
  PERSONALITY_ATTRIBUTE_COMMUNICATIVE = 'PERSONALITY_ATTRIBUTE_COMMUNICATIVE',
  PERSONALITY_ATTRIBUTE_ATTENTIVE = 'PERSONALITY_ATTRIBUTE_ATTENTIVE',
  PERSONALITY_ATTRIBUTE_ADAPTIVE = 'PERSONALITY_ATTRIBUTE_ADAPTIVE',
  PERSONALITY_ATTRIBUTE_CREATIVITY = 'PERSONALITY_ATTRIBUTE_CREATIVITY',
  PERSONALITY_ATTRIBUTE_ORDERED = 'PERSONALITY_ATTRIBUTE_ORDERED',
  PERSONALITY_ATTRIBUTE_EXTRAVERSION = 'PERSONALITY_ATTRIBUTE_EXTRAVERSION',
  PERSONALITY_ATTRIBUTE_AGREEABLENESS = 'PERSONALITY_ATTRIBUTE_AGREEABLENESS',
  PERSONALITY_ATTRIBUTE_CONSCIENTIOUSNESS = 'PERSONALITY_ATTRIBUTE_CONSCIENTIOUSNESS',
  PERSONALITY_ATTRIBUTE_EMOTIONAL_STABILITY = 'PERSONALITY_ATTRIBUTE_EMOTIONAL_STABILITY',
  PERSONALITY_ATTRIBUTE_OPENNESS = 'PERSONALITY_ATTRIBUTE_OPENNESS',
  SALES_CONVERSION_OVERALL = 'SALES_CONVERSION_OVERALL',
  SALES_CONVERSION_PERFECTIONIST_CONSUMER = 'SALES_CONVERSION_PERFECTIONIST_CONSUMER',
  SALES_CONVERSION_BRAND_LOYAL_CONSUMER = 'SALES_CONVERSION_BRAND_LOYAL_CONSUMER',
  SALES_CONVERSION_IMPULSIVE_CONSUMER = 'SALES_CONVERSION_IMPULSIVE_CONSUMER',
  SALES_CONVERSION_HEDONISTIC_CONSUMER = 'SALES_CONVERSION_HEDONISTIC_CONSUMER',
  SALES_CONVERSION_PRICE_VALUE_CONSUMER = 'SALES_CONVERSION_PRICE_VALUE_CONSUMER',
  WELLNESS_ASSESMENT_GENERAL_WELLNESS = 'WELLNESS_ASSESMENT_GENERAL_WELLNESS',
  WELLNESS_ASSESMENT_WB_ENERGY = 'WELLNESS_ASSESMENT_WB_ENERGY',
  WELLNESS_ASSESMENT_WB_STRESS_CONTROL = 'WELLNESS_ASSESMENT_WB_STRESS_CONTROL',
  WELLNESS_ASSESMENT_WB_EMOTION = 'WELLNESS_ASSESMENT_WB_EMOTION',
  WELLNESS_ASSESMENT_WB_SOCIAL = 'WELLNESS_ASSESMENT_WB_SOCIAL',
  WELLNESS_ASSESMENT_WB_COPING = 'WELLNESS_ASSESMENT_WB_COPING',
  WELLNESS_MENTAL_HEALTH_WB_ENERGY = 'WELLNESS_MENTAL_HEALTH_WB_ENERGY',
  WELLNESS_MENTAL_HEALTH_WB_STRESS_CONTROL = 'WELLNESS_MENTAL_HEALTH_WB_STRESS_CONTROL',
  WELLNESS_MENTAL_HEALTH_WB_EMOTION = 'WELLNESS_MENTAL_HEALTH_WB_EMOTION',
  WELLNESS_MENTAL_HEALTH_WB_SOCIAL = 'WELLNESS_MENTAL_HEALTH_WB_SOCIAL',
  WELLNESS_MENTAL_HEALTH_WB_COPING = 'WELLNESS_MENTAL_HEALTH_WB_COPING',
  WELLNESS_MENTAL_HEALTH_GENERAL_WELLNESS = 'WELLNESS_MENTAL_HEALTH_GENERAL_WELLNESS',
  WELLNESS_MENTAL_HEALTH_DEPPRESSION = 'WELLNESS_MENTAL_HEALTH_DEPPRESSION',
  WELLNESS_MENTAL_HEALTH_BURNOUT = 'WELLNESS_MENTAL_HEALTH_BURNOUT',
  WELLNESS_MENTAL_HEALTH_ATTRITION = 'WELLNESS_MENTAL_HEALTH_ATTRITION',
}
