import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faBoxOpen,
  faBuilding,
  faChartBar,
  faChartLine,
  faCogs,  faHistory,
  faLink,
  faReceipt,
  faUser,
  faUsers,
  IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { AuthorizationService } from 'src/app/services/authorization-service/authorization.service';
import { UserSession } from 'src/app/services/authorization-service/user-session';
import { RouterService } from 'src/app/services/router-service/router.service';
import { SideMenuService } from 'src/app/services/side-menu-service/side-menu.service';
import { SvgIcon } from 'src/app/shared/icon/components/svg/svg.component';
import { IconType } from 'src/app/shared/icon/configuration/icon-type';
import { UserRole } from 'src/app/shared/models/enums/user-role';
import { UserMenuConfiguration } from 'src/app/shared/models/user-menu-configuration';

export interface CurrentRoles {
  isMaster: boolean
  isCompanyOperator: boolean
  isTeamAdministrator: boolean
  isWellnessOperator: boolean
  isPersonalRecorderOperator: boolean
}

interface SideMenuConfiguration {
  callHistorySection: boolean;
  companyInformationSection: boolean;
  users: boolean;
  teams: boolean;
  reports: boolean;
  webhooks: boolean;
  license: boolean;
  companies: boolean;
  wellnessSection: boolean;
  wellnessInfo: boolean;
  wellnessUsers: boolean;
  wellnessReports: boolean;
  personalRecorderLinksSection: boolean;
  personalRecorderLinks: boolean;
  personalRecordeReports: boolean;
}

const defaultConfiguration: SideMenuConfiguration = {
  callHistorySection: true,
  companyInformationSection: false,
  users: false,
  teams: false,
  reports: false,
  webhooks: false,
  license: false,
  companies: false,
  wellnessSection: false,
  wellnessInfo: true,
  wellnessUsers: true,
  wellnessReports: true,
  personalRecorderLinksSection: false,
  personalRecorderLinks: true,
  personalRecordeReports: true,
}

const sideMenuConfiguration: Map<UserRole, Partial<SideMenuConfiguration>> = new Map([
  [UserRole.MASTER, {
    callHistorySection: false,
    companyInformationSection: true,
    users: true,
    license: false,
    companies: true
  }],
  [UserRole.COMPANY_OPERATOR, {
    callHistorySection: true,
    companyInformationSection: true,
    users: true,
    teams: true,
    reports: true,
    webhooks: true,
    license: true,
  }],
  [UserRole.TEAM_OPERATOR, {
    callHistorySection: true,
    companyInformationSection: true,
    users: true,
    teams: true,
    reports: true,
  }]
])

@Component({
  selector: 'porcupine-manage-side-navigation',
  templateUrl: './manage-side-navigation.component.html',
  styleUrls: ['./manage-side-navigation.component.scss']
})
export class ManageSideNavigationComponent implements OnInit, OnDestroy {
  @Input() public currentRoles: CurrentRoles = {
    isMaster: false,
    isCompanyOperator: false,
    isTeamAdministrator: false,
    isWellnessOperator: false,
    isPersonalRecorderOperator: false,
  };

  @Input() public userMenu: UserMenuConfiguration[] = [];
  @Input() public userSession: UserSession | undefined;

  public isFolded$: Observable<boolean> = this.sideMenuService.isMenuFolded().pipe(shareReplay(1));

  public menuConfiguration: SideMenuConfiguration = defaultConfiguration

  public isCompanyInformationSectionCollapsed: boolean = true;
  public isRecorderSectionCollapsed: boolean = true;
  public isWellnessSectionCollapsed: boolean = true;
  public isActive: boolean = false;
  public actives: boolean = false;
  public iconType: typeof IconType = IconType;
  public icons: { [key:string]: IconDefinition } = {
    faBuilding,
    faUser,
    faChartBar,
    faUsers,
    faBoxOpen,
    faHistory,
    faLink,
    faCogs,
    faReceipt,
    faChartLine,
    faAngleDoubleLeft,
    faAngleDoubleRight
  }

  public svgIconType: typeof SvgIcon = SvgIcon;
  private untilDestroyed$: Subject<void> = new Subject();

  constructor(
    private routerService: RouterService,
    private authorizationService: AuthorizationService,
    private sideMenuService: SideMenuService) {}

  public ngOnInit(): void {
    this.authorizationService.getUserSession()
    .pipe(takeUntil(this.untilDestroyed$))
    .subscribe(() => {
      this.menuConfiguration = this.getRoleConfiguration();
    })

    this.isCompanyInformationSectionCollapsed = !this.currentRoles.isMaster;
  }

  public ngOnDestroy(): void {
    this.untilDestroyed$.next();
    this.untilDestroyed$.complete();
  }

  public isCompanyInformationRouteActive(): boolean {
    return this.routerService?.getRouterUrl()?.includes('manage/admin/');
  }

  public isWellnessRouteActive(): boolean {
    return this.routerService?.getRouterUrl()?.includes('manage/wellness/');
  }

  public isRecorderRouteActive(): boolean {
    return this.routerService?.getRouterUrl()?.includes('manage/recorder/');
  }

  public onClick(): void {
    if (window.innerWidth < 992) this.close();
  }

  public open(): void {
    this.sideMenuService.expandMenu();
  }

  public close(): void {
    this.sideMenuService.collapseMenu();
  }

  private getRoleConfiguration(): SideMenuConfiguration {
    const config: SideMenuConfiguration = {
      ...defaultConfiguration,
      ...this.isWellnessOrPersonalRecorderOperator()
    }

    switch (true) {
      case this.currentRoles.isMaster:
        return { ...config, ...sideMenuConfiguration.get(UserRole.MASTER) }
      case this.currentRoles.isCompanyOperator:
        return { ...config, ...sideMenuConfiguration.get(UserRole.COMPANY_OPERATOR) }
      case this.currentRoles.isTeamAdministrator:
        return { ...config, ...sideMenuConfiguration.get(UserRole.TEAM_OPERATOR) }
      default:
        return config;
    }
  }

  private isWellnessOrPersonalRecorderOperator(): Partial<SideMenuConfiguration> {
    const configuration: Partial<SideMenuConfiguration> = {
      wellnessSection: false,
      personalRecorderLinksSection: false
    };

    if (this.currentRoles.isWellnessOperator) configuration.wellnessSection = true;
    if (this.currentRoles.isPersonalRecorderOperator) configuration.personalRecorderLinksSection = true;

    return configuration;
  }
}
