import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeES from '@angular/common/locales/es';
import localePL from '@angular/common/locales/pl';
import { APP_INITIALIZER, LOCALE_ID, NgModule, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { ForbiddenComponent } from './_unauthorized/forbidden/forbidden.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { WINDOW, WINDOW_PROVIDERS } from './providers/window.provider';
import { environmentFactory } from './services/environment-service/environment.factory';
import { EnvironmentService } from './services/environment-service/environment.service';
import { InsightsModule } from './services/insights-service/insights.module';
import { httpBaseUrlInterceptorProviders } from './services/interceptors/http-base-url.providers';
import { httpBearerInterceptorProviders } from './services/interceptors/http-bearer.provider';
import { httpErrorInterceptorProviders } from './services/interceptors/http-error.provider';
import { httpRefreshTokenInterceptorProviders } from './services/interceptors/http-refresh-token.provider';
import { LanguageService } from './services/language-service/language.service';
import { ContextSideNavigationModule } from './shared/context-side-navigation/context-side-navigation.module';
import { DemoModule } from './shared/demo/demo.module';
import { MainLayoutModule } from './shared/main-layout/main-layout.module';
import { ConfirmModalComponent } from './shared/modals/confirm-modal/confirm-modal.component';

registerLocaleData(localeES);
registerLocaleData(localePL);

import { httpCacheInterceptorProviders } from './services/interceptors/http-cache.providers';
import { CustomTranslateLoader } from './services/translation-loader';

import '@progress/kendo-angular-intl/locales/pl/all';
import '@progress/kendo-angular-intl/locales/es/all';
import 'hammerjs';

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    ConfirmModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    BsDropdownModule.forRoot(),
    MainLayoutModule,
    CoreModule,
    ContextSideNavigationModule,
    InsightsModule,
    DemoModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient, EnvironmentService, WINDOW],
    }
    }),
  ],
  providers: [
    WINDOW_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: environmentFactory,
      deps: [EnvironmentService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [LanguageService],
      useFactory: (languageService) => languageService.getLocaleLanguage(),
    },
    httpBaseUrlInterceptorProviders,
    httpRefreshTokenInterceptorProviders,
    httpErrorInterceptorProviders,
    httpBearerInterceptorProviders,
    httpCacheInterceptorProviders,
],
  bootstrap: [AppComponent]
})
export class AppModule { }
