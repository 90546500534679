import { faDollarSign, faHandHoldingHeart, faHeartbeat, faPoll, faTachometerAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { SvgIcon } from '../../icon/components/svg/svg.component';
import { IconType } from '../../icon/configuration/icon-type';
import { ProductType } from '../../models/enums/product-type.enum';

export enum AccordionType {
    FOR_RISK_MANAGMENT = 'for-risk-managment',
    COLLECTION = 'collection',
    FOR_TALENT = 'for-talent',
    MENTAL_HEALTH = 'mental-health',
    FOR_SALES = 'for-sales',
    CUSTOM = 'custom',
  }

export const sideMenuButtonsConfiguration: Map<ProductType, any> = new Map([
    [ProductType.DASHBOARD, { label: 'dashboard', icon: faTachometerAlt, urlSuffix: 'dashboard' }],
    [ProductType.LOAN_DEFAULT, { label: 'loanDefault', icon: SvgIcon.LOAN_DEFAULT, urlSuffix: '/loan-default' }],
    [ProductType.COLLECTIONS, { label: 'deptCollection', icon: SvgIcon.COLLECTIONS, urlSuffix: '/collections' }],
    [ProductType.CANDIDATE_SUCCESS, { label: 'candidateSuccess', icon: SvgIcon.CANDIDATE_SUCCESS, urlSuffix: '/candidate-success' }],
    [ProductType.EMPLOYEE_CHURN, { label: 'employeeChurn', icon: SvgIcon.EMPLOYEE_CHURN, urlSuffix: '/employee-churn' }],
    [ProductType.SALES_CONVERSION, { label: 'salesConversion', icon: SvgIcon.SALES_CONVERSION, urlSuffix: '/sales-conversion' }],
    [ProductType.PERSONALITY_ATTRIBUTE, { label: 'personalityAttribute', icon: SvgIcon.PERSONALITY_ATTRIBUTE, urlSuffix: '/personality-attribute' }],
    [ProductType.WELLNESS, { label: 'mentalHealthMonitoring', icon: '', faIcon: faHeartbeat, urlSuffix: '/wellness' }],
    [ProductType.MENTAL_HEALTH, { label: 'mentalHealthScreening', icon: '', faIcon: faHandHoldingHeart, urlSuffix: '/mental-health' }],
    [ProductType.MOBILE_TRADING, { label: 'mobileTrading', icon: '', faIcon: '', urlSuffix: '' }],
    [ProductType.RECRUIT, { label:  'recruit', icon: '', faIcon: '', urlSuffix: '' }],
    [ProductType.FRAUD_PREDICTOR, { label: 'fraud', icon: '', faIcon: '', urlSuffix: '' }],
    [ProductType.CALL_ANALYZER, { label: '', icon: '', urlSuffix: '' }],
]);

export const accordionIconConfiguration: Map<AccordionType, {faIcon: IconDefinition, customIcon: IconType, svg: SvgIcon}> = new Map([
    [AccordionType.COLLECTION, { faIcon: faDollarSign, customIcon: null, svg: null }],
    [AccordionType.FOR_RISK_MANAGMENT, { faIcon: null, customIcon: IconType.UMBRELLA_WITH_DOLLAR, svg: null }],
    [AccordionType.FOR_SALES, { faIcon: null, customIcon: IconType.SYNC_DOLLAR, svg: null }],
    [AccordionType.FOR_TALENT, { faIcon: null, customIcon: IconType.HUMAN_LOUPE, svg: null }],
    [AccordionType.MENTAL_HEALTH, { faIcon: null, customIcon: null, svg: SvgIcon.HEARTBEAT_PULSE }],
    [AccordionType.CUSTOM, { faIcon: faPoll, customIcon: null, svg: null }],
])
