import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { NavigationLoaction } from 'src/app/services/authorization-service/authorization.service';

import { ProductType } from './models/enums/product-type.enum';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  public activeStreamId$: Subject<string> = new BehaviorSubject(null);
  public activeUserId$: Subject<string> = new BehaviorSubject(null);
  public productTypes$: Subject<ProductType[]> = new BehaviorSubject([]);
  public licenseProducts$: Subject<any> = new BehaviorSubject(null);

  constructor(private router: Router) {}

  public getActiveStreamId(): Observable<string> {
    return this.activeStreamId$.pipe(shareReplay(1))
  }

  public setActiveStream(streamId: string): void {
    this.activeStreamId$.next(streamId);
  }

  public getActiveUserId(): Observable<string> {
    return this.activeUserId$.pipe(shareReplay(1))
  }

  public setActiveUser(userId: string): void {
    this.activeUserId$.next(userId);
  }

  public clearActiveStream(): void {
    this.activeStreamId$.next(undefined);
  }

  public async clearActiveStreamAndGoDashboard(navigateLocation: NavigationLoaction = NavigationLoaction.MANAGE_HOME): Promise<void> {
    this.clearActiveStream();
    this.router.navigate([navigateLocation]);
  }

  public setProducts(products: ProductType[]): void {
    this.productTypes$.next(products)
  }

  public setLicenseProducts(products: any): void {
    this.licenseProducts$.next(products)
  }

  public getProducts():  Observable<ProductType[]> {
    return this.productTypes$.asObservable().pipe(shareReplay(1));
  }

  public getLicenseProducts():  Observable<any> {
    return this.licenseProducts$.asObservable().pipe(shareReplay(1));
  }
}
