import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class HttpBearerInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const bearerToken: string = localStorage.getItem('bearerToken');
    const refreshToken: string = localStorage.getItem('refreshToken');
    if (request.headers.get('skipAuthorizationHeader')) {
      return next.handle(request.clone());
    }
    const modifiedReq = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${bearerToken}`).set('RefreshToken', refreshToken || ''),
    });

    return next.handle(modifiedReq);
  }
}
