import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

import { TranslateService } from '@ngx-translate/core';

import { WINDOW } from 'src/app/providers/window.provider';

import { EnvironmentService } from '../environment-service/environment.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public languagesDropdown$ = new ReplaySubject<{text: string, value: string}[]>(1);
  public languages: string[] = ['en', 'es'];
  public currentLanguage;
  private translateLocales = {
    en: 'en-US',
    es: 'es-ES',
  }
  contentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*',
    'catchError': 'false',
    'skipAuthorizationHeader': 'true' });

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService,
    private http: HttpClient,
    private environmentService: EnvironmentService,
    @Inject(WINDOW) private window: Window,
  ) { this.reloadTranslations() }

  public reloadTranslations(): void {
    const blobUrl = this.environmentService.getEnvironment().blobStorageUrl;
    const hostName = this.window.location.hostname
    const translationUrl = `${blobUrl}/templates/${hostName}-en.json`;

    this.http.get<any>(
      translationUrl,
      { headers: this.contentHeader }
    ).pipe(
      take(1),
      catchError(() => of(this.translateLocales))
    ).subscribe((response) => {
      const i18nConfig = response?.I18N;
      const langauges = i18nConfig?.LOCALES?.map(l => l.value);
      let browserLang;

      this.translate.addLangs(langauges ? langauges : this.languages);

      this.cookieService.check('lang')
        ? browserLang = this.cookieService.get('lang')
        : browserLang = this.translate.getBrowserLang();
      this.currentLanguage = browserLang.match(new RegExp(i18nConfig.LANG_REGEXP ?? 'en')) ? browserLang : 'en';
      this.translate.use(this.currentLanguage);

      if (i18nConfig?.LOCALES) {
        this.languagesDropdown$.next(i18nConfig.LOCALES)
      }
    });
  }

  public getLanguagesDropdown(): Observable<{text: string, value: string}[]> {
    return this.languagesDropdown$.asObservable();
  }

  public getCurrentLanguage(): string {
    return this.currentLanguage
  }

  public getLocaleLanguage(): string {
    return this.translateLocales[this.currentLanguage] || 'en-US';
  }

  public setLanguage(lang: 'en' | 'es' | string): void {
    let browserLang;
    this.cookieService.check('lang')
    ? browserLang = this.cookieService.get('lang')
    : browserLang = this.translate.getBrowserLang();

    if (lang !== browserLang) {
      this.cookieService.set('lang', lang);
      window.location.reload();
    }
  }
}
