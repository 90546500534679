export enum Score {
  TEMPERAMENT='TEMPERAMENT',
  ENGAGED='ENGAGED',
  DETERMINED='DETERMINED',
  DEPENDABLE='DEPENDABLE',
  COMMUNICATIVE='COMMUNICATIVE',
  ATTENTIVE='ATTENTIVE',
  ADAPTIVE='ADAPTIVE',
  OPEN='OPEN',
  WBENERGY='WBENERGY',
  WBEMOTION='WBEMOTION',
  WBSOCIAL='WBSOCIAL',
  OVERALLWELLBEING='OVERALLWELLBEING',
  WBCOPING='WBCOPING',
  ORDERED='ORDERED',
  WBSTRESSCONTROL='WBSTRESSCONTROL',
  ENERGYLEVEL='ENERGYLEVEL',
  AMBITION='AMBITION',
  INTEGRITY='INTEGRITY',
  SYSTEMATIC='SYSTEMATIC',
  COMMUNICATION='COMMUNICATION',
  INNOVATION='INNOVATION',
  COOPERATION='COOPERATION',
  ADJUSTMENT='ADJUSTMENT',
  CREATIVITY='CREATIVITY',
  FINANCIALRISK='FINANCIALRISK',
  SALESFIT='SALESFIT',
  MANAGEMENTFIT='MANAGEMENTFIT',
  CUSTOMERSERVICEFIT='CUSTOMERSERVICEFIT',
  RDFIT='RDFIT',
  LEARNING='LEARNING',
  ATTRITION='ATTRITION',
  BURNOUT='BURNOUT',
  DISSATISFACTION='DISSATISFACTION',
  UPSELLPROBABILITY='UPSELLPROBABILITY',
  DEBTREPAYMENTPROBABILITY='DEBTREPAYMENTPROBABILITY',
  PERFECTIONISTCONSUMER='PERFECTIONISTCONSUMER',
  BRANDLOYALCONSUMER='BRANDLOYALCONSUMER',
  IMPULSIVECONSUMER='IMPULSIVECONSUMER',
  HEDONISTICCONSUMER='HEDONISTICCONSUMER',
  PRICEVALUECONSUMER='PRICEVALUECONSUMER',
  AGREEABLENESS='AGREEABLENESS',
  EXTRAVERSION='EXTRAVERSION',
  CONSCIENTIOUSNESS='CONSCIENTIOUSNESS',
  EMOTIONALSTABILITY='EMOTIONALSTABILITY',
  OPENNESS='OPENNESS',
  ATTITUDELEVEL='ATTITUDELEVEL',
  SATISFACTION='SATISFACTION',
  DEPRESSION='DEPRESSION',
  TOADDFREECONVERSIONPROBABILITY='TOADDFREECONVERSIONPROBABILITY',
  TOVIPCONVERSIONPROBABILITY='TOVIPCONVERSIONPROBABILITY',
  OVERALLWELLBEING10SCALE='OVERALLWELLBEING10SCALE',
  WBSTRESSCONTROL10SCALE='WBSTRESSCONTROL10SCALE',
  WBENERGY10SCALE='WBENERGY10SCALE',
  WBEMOTION10SCALE='WBEMOTION10SCALE',
  WBSOCIAL10SCALE='WBSOCIAL10SCALE',
  WBCOPING10SCALE='WBCOPING10SCALE',
  VOLUNTARYCHURN='VOLUNTARYCHURN',
  NONVOLUNTARYCHURN='NONVOLUNTARYCHURN',
  AGVOLUNTARYCHURN='AGVOLUNTARYCHURN',
  FRAUD='FRAUD',
}
