import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  private printableElement$: Subject<string> = new BehaviorSubject(null);

  public getPrintableElement(): Observable<string> {
    return this.printableElement$.asObservable();
      }

  public setPrintableElement(element: string): void {
    this.printableElement$.next(element);
  }

  public clear(): void {
    this.printableElement$.next(null);
  }

  public export(data: string): void {
    const pdfWindow: Window = window.open('about:blank', '_blank');

    pdfWindow.document.write(`
    <style>
    body { margin:0; padding:0; overflow: hidden; }
    iframe { width: 100%; height: 100%; }
    </style>
    <iframe src="${encodeURI(data)}" type="application/pdf">
        <div>No online PDF viewer installed</div>
    </iframe>
    `);
  }
}
