import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

import { NgxPrintDirective } from '../shared/_directives/ngx-print.directive';
import { IconModule } from '../shared/icon/icon.module';
import { KendoUiModule } from '../shared/kendo-ui/kendo-ui.module';
import { NotifyPopupModule } from '../shared/notify-popup/notify-popup.module';
import { ManageSideNavigationComponent } from './components/side-navigation/manage-side-navigation.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';

@NgModule({
  declarations: [ManageSideNavigationComponent, TopMenuComponent, NgxPrintDirective],
  exports: [ManageSideNavigationComponent, TopMenuComponent],
  imports: [
    CommonModule,
    RouterModule,
    NotifyPopupModule,
    IconModule,
    FontAwesomeModule,
    KendoUiModule,
    AccordionModule,
    CollapseModule,
    ReactiveFormsModule,
    TranslateModule.forChild({ extend: true }),
  ]
})
export class CoreModule { }
