import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  private isTimerStarted = false;
  public dateNow = new Date();
  public dDay = new Date();
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;

  public timeDifference: any;

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
  }

  public startTimer(): void {
    if (!this.isTimerStarted) {
      this.dDay.setMinutes(this.dDay.getMinutes() + 1);
      this.getTimeDifference();
      this.isTimerStarted = true;
    }
  }

  public resetTimer(): void {
    this.isTimerStarted = false;
    this.getTimeDifference();
  }

  public getRemainingTime(): number {
    this.getTimeDifference();
    return this.timeDifference;
  }
}
