import { ErrorHandler, Injectable } from '@angular/core';

import { InsightsService } from './insights.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private insightsService: InsightsService) { }
  handleError(error: any): void {
    console.error(error);
    this.insightsService.logException(error);
  }
}
