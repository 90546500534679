import { NgModule } from '@angular/core';
import { PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';

import { MicroFrontendRouteReuseStrategy } from 'src/spa-services/route-reuse-strategy';

import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  { path: '', redirectTo: '/manage/home', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'user/password/change',
    loadChildren: () => import('./_unauthorized/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./_unauthorized/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./_authorized/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'manage',
    loadChildren: () => import('./_manage/manage.module').then(m => m.ManageModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./_unauthorized/login/login.module').then(m => m.LoginModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: false })],
  exports: [RouterModule],
  providers: [{ provide: RouteReuseStrategy, useClass: MicroFrontendRouteReuseStrategy }]
})
export class AppRoutingModule { }
