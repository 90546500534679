import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DemoButtonComponent } from './components/demo-button/demo-button.component';
import { DemoSelectModalComponent } from './modals/demo-select-modal/demo-select-modal.component';

@NgModule({
  declarations: [
    DemoButtonComponent,
    DemoSelectModalComponent
  ],
  exports: [
    DemoButtonComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
})
export class DemoModule { }
