import { Injectable } from '@angular/core';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { EnvironmentService } from 'src/app/services/environment-service/environment.service';

@Injectable({ providedIn: 'root' })
export class InsightsService {
  private appInsights: ApplicationInsights;
  constructor(private environmentService: EnvironmentService) {}

  public startMonitoring(): void {
    if (!this.environmentService.getEnvironment().insights.enableTracing) return;
    this.setupInsights();
  }

  public setupInsights(): void {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.environmentService.getEnvironment().insights.instrumentationKey,
        enableAutoRouteTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

  public logException(exception: Error, severityLevel?: number): void {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
    });
  }
}
